<template>
  <div style="width:100%;height:100%;" class="login-background">
     
    <!-- <v-app-bar color="white lighten-1 dense">
      <v-toolbar-title class="orange--text text--darken-3"><div class="title">腾泡报价小店 - 商家中心</div></v-toolbar-title>
    </v-app-bar> -->
     
    <v-card max-width="420px" height="100%" flat>
         <v-card-text  class="left">
           <img src="@/assets/logo.png" alt="" height="100px" style="margin-top:80px">
           <div class="left-text">
              <div class="left-font-text1">腾泡报价小店</div>
              <div class="left-font-text2">商家中心</div>
           </div>
          <v-spacer></v-spacer>
           <div class="left-img">
             <img src="@/assets/signin-illustration.jpg" alt="" width="100%">
           </div>
         </v-card-text>
    </v-card>
    <v-card width="100%" class="right" color="#f2f5f8" flat>
            <v-alert v-show="messageError" type="error" :class="messageError?'message':''" dense text>
                {{message}}
            </v-alert>
            <v-card
            justify="center" 
            align="center"
            width="480"
            style="margin-top:100px"
            flat
            >
              <v-card-title class="justify-center display-1 mb-2">
                <span style="font-weight:400">商家登录</span> 
              </v-card-title>
              <!-- <v-card-subtitle>
                Sign in to your account
              </v-card-subtitle> -->
              <v-tabs  align-with-title color="#f57c00" >
                    <v-tab class="text-h6">账号密码登录</v-tab>
                    <v-tab class="text-h6">手机验证登录</v-tab>
                    <v-tab-item>
                      <v-card-text>
                            <v-form
                            ref="formPwd"
                            v-model="valid"
                            lazy-validation
                            >
                              <v-text-field v-model="mobile" class="mx-auto col-11 account-input" outlined label="账号" :rules="[mobileRule]" require />
                              <v-text-field
                                  v-model="password"
                                  class="my-4 mx-auto col-11"
                                  :type="show1 ? 'text' : 'password'"
                                  :rules="[v => !!v || '密码不能为空']"
                                  label="密码"
                                  outlined
                                  require
                                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                  @click:append="show1 = !show1"
                                  @keyup.enter="Login"
                              />
                              <v-btn
                              class="my-4 mx-auto col-11"
                              style="height:52px;display:block;margin:0 auto 40px;font-size:16px"
                              color="#f57c00"
                              @click="validatePwd"
                              >
                                <span style="color:#fff;font-weight:600;">登录</span> 
                              </v-btn>
                          </v-form>
                          <div style="margin-top:40px">
                            <router-link :to="{path :'forgetpassword'}" style="color:#f57c00;">忘记密码</router-link>
                          </div>
                      </v-card-text>
                    </v-tab-item>
                    <v-tab-item>
                        <v-container fluid>
                          <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            >
                              <v-text-field v-model="mobile" outlined class="mx-auto col-11" label="手机号" :rules="[mobileRule]" require />
                               <div class="validate-mobile">
                                <v-text-field
                                    v-model="validates"
                                    class="mx-auto col-7"
                                    :type="'text'"
                                    label="输入验证码"
                                    :rules="[v => !!v || '验证码不能为空']"
                                    outlined
                                    require
                                    oninput="value=value.replace(/[^\d]/g,'')"
                                    @keyup.enter="Login"
                                />
                                <div class="col-4">
                                  <v-btn
                                  outlined
                                  style="height:45px;margin-bottom:30px"
                                  color="#f57c00"
                                  v-if="codeShow"
                                  @click="getDynamic"
                                  >
                                  {{dynamic}}
                                  </v-btn>
                                  <v-btn
                                  outlined
                                  style="height:45px;margin-bottom:30px"
                                  color="#f57c00"
                                  v-else
                                  @click="getDynamic"
                                  >
                                  {{count}}秒后重试
                                  </v-btn>
                                </div>
                                </div> 
                            <v-btn
                              class="my-4 mx-auto col-11"
                              style="height:52px;display:block;margin:0 auto 40px;"
                              color="#f57c00"
                              @click="validate"
                              >
                                <span style="color:#fff;font-weight:600;font-size:16px">登录</span> 
                              </v-btn>
                        </v-form>
                        </v-container>
                    </v-tab-item>
              </v-tabs>
            </v-card>
            <div class="">
             
              <v-list-item>
                <v-list-item-content class="center-text">&copy;{{ new Date().getFullYear() }}</v-list-item-content><a href="https://www.tp-x.cn/" style="text-decoration:none;font-size:14px;color:#000"  target="_blank">&nbsp;腾泡科技</a>
              </v-list-item>
              
            </div>
    </v-card>
  </div>
</template>

<script>
import {loginByPwd,loginByVld,getloginCode} from '@/api/user.js'
import jwtDecode from 'jwt-decode'
export default {
  data() {
    return {
      valid:true,
      dynamic:'获取验证码',
      codeShow:true,
      count:60,
      password: '',
      messageError:false,
      message:'',
      mobile:'',
      mobileRules:[ v => /^[1][3456789][0-9]{9}$/.test(v) || '请正确填写手机号'],
      validates:'',
      passwordAgain: '',
      rememberMe: false,
      show1: false,
      isLogin: true,
      decode:{},
    }
  },
  methods: {
    Login() {
     this.validatePwd()
    },
    mobileRule(value){
      if(value.length === 0){
        return "手机号不能为空";
      }
      else if(value.length > 0){
        if(value.length===11){
          var Reg = /^[1][3456789][0-9]{9}$/;
          if(!Reg.test(value)){
            return "请正确填写手机号";
          }else{
            return true
          }
        }
        else if(value.length>11){
            return "请正确填写手机号";
        }else{
          return false
        }
      }
    },
    // 获取动态验证码
    getDynamic(){
        var Reg = /^[1][3456789][0-9]{9}$/;
        if(Reg.test(this.mobile)){
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                var params={
                    phonenumber:this.mobile
                  }
                  getloginCode(params).then(res=>{
                    if(res.data.code===200){
                      
                    }else{
                        this.$nextTick(()=>{
                          this.message = res.data.message;
                          this.messageError = true;
                          setTimeout(()=>{
                              this.messageError = false;
                          },6000)
                        })
                    }
                  })
                this.codeShow = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                    } else {
                    this.codeShow = true;
                    clearInterval(this.timer);
                    this.timer = null;
                    }
                }, 1000)
            }
        }else{
          this.$nextTick(()=>{
            this.message = "请先填写手机号";
            this.messageError = true;
            setTimeout(()=>{
                this.messageError = false;
            },2000)
          })
        }
       
    },
    validate(){
       if (!this.mobile || !this.validates) {
         this.$refs.form.validate()
         this.$nextTick(()=>{
            this.message = "手机号与验证码不能为空";
            this.messageError = true;
            var timer = setTimeout(()=>{
                clearTimeout(timer);
                this.messageError = false;
            },2000)
          })
          return false;
        }
        var params={
          phonenumber:this.mobile,
          verifycode:this.validates
        }
        loginByVld(params).then(res=>{
           if(res.data.code===200){
          window.sessionStorage.setItem('token',res.data.data.token);
            this.decode =  jwtDecode(res.data.data.token);
            console.log( this. decode)
            this.$store.commit('setCreated',this.decode.created)
            this.$store.commit('setIss',this.decode.iss)
            this.$store.commit('setSub',this.decode.sub)
            this.$router.push('/home');
           }else{
              this.$nextTick(()=>{
              this.message = res.data.message;
              this.messageError = true;
              var timer = setTimeout(()=>{
                clearTimeout(timer);
                this.messageError = false;
              },2000)
            })
           }
        })
    },
    validatePwd(){
        if (!this.mobile || !this.password) {
          this.$refs.formPwd.validate()
          this.$nextTick(()=>{
            this.message = "手机号与密码不能为空";
            this.messageError = true;
            setTimeout(()=>{
                this.messageError = false;
            },2000)
          })
          return false;
        }
        var params = {
          username:this.mobile,
          password:this.password
        }
        loginByPwd(params).then(res=>{
          // console.log(res);
          if(res.data.code===200){
            window.sessionStorage.setItem('token',res.data.data.token);
            this.decode =  jwtDecode(res.data.data.token);
            console.log( this. decode);
            this.$store.commit('setCreated',this.decode.created)
            this.$store.commit('setIss',this.decode.iss)
            this.$store.commit('setSub',this.decode.sub)
            this.$router.push('/home');
          }else{
            this.$nextTick(()=>{
              this.message = res.data.message;
              this.messageError = true;
              setTimeout(()=>{
                this.messageError = false;
              },2000)
            })
          }
        })
    },
  }
}
</script>

<style scoped>
.validate{
    justify-content: space-between;
    align-items: center;
}
.title{
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  margin-left:70px
}
.title::before{
  content: '';
  position: absolute;
  top: 0;
  left: -70px;
  width: 50px;
  height: 55px;
  background:url(../../assets/logo.png) no-repeat;
  background-position:0 0px;
  background-size: 100%;
}
.cotainer{
  position: relative;
}
.forget-pwd{
  position: absolute;
  top: 170px;
  right: 5%;
}
.login-background{
  background-color: #f2f5f8;
  display: flex;
}
.left{
  height: 100%;
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.left-text{
  margin-top:40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.left-font-text1{
  font-size: 40px;
  color: #f57c00;
  font-weight: 600;
}
.left-font-text2{
  margin-top: 32px;
  font-size: 24px;
  color:#000;
  font-weight: 800;
}
.right{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.center-text{
  font-size: 14px;
}
.validate-mobile{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.message{
  position: absolute;
  opacity: 0;
  top:0px;
  right: 50%;
  transform: translateX(50%);
  padding-right: 50px;
  animation-name: example;
  animation-duration: 6s;
}
@keyframes example {
  0%   {  opacity: 1; right: 50%;transform: translateX(50%); top:0px;}
  10%  { opacity: 1; right: 50%;; top:50px;}
  100% {opacity: 0; right: 50%;; top:50px;}
}
</style>
